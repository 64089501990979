<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Edit Employee</h1>
    <div>
      <BarberForm @submit="updateBarber" :initial-data="initData" />
    </div>
  </div>
  <BarberInfos
    :salon-name="data?.salon_name"
    :salon-id="data?.salon_id"
    :barber-id="route.params?.id"
  />
  <BarberServices :barber-id="route.params?.id" />
  <BarberAvailabilities :barber-id="route.params?.id" />
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, ref } from "vue";

import BarberServices from "./BarberServices";
import BarberAvailabilities from "./BarberAvailabilities";
import BarberForm from "./Form";
import BarberInfos from "./BarberInfos";
import { useBarberForm } from "@/composables/barber";

const initData = ref({});
const { update, getDetail } = useBarberForm();
const router = useRouter();
const route = useRoute();

const updateBarber = async (data) => {
  await update(route.params.id, data);
  await router.push({ name: "barbers" });
};

onBeforeMount(async () => {
  initData.value = await getDetail(route.params.id);
});
</script>

<style scoped></style>
